import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {debounceTime, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {User} from '../../../../infrastructure/auth/models/user';
import {MonolithApiClient} from '../api/monolith-api-client';
import {IdentityService} from '../../../users/core/identity.service';

@Injectable({
    providedIn: 'root',
})
export class CatalogUnreadGuestsCountService {
    public readonly unreadGuestsCount$: Observable<number>;
    private readonly refreshSubject$: BehaviorSubject<void>;

    constructor(
        private readonly catalogClient: MonolithApiClient,
        private readonly identityService: IdentityService) {
        this.refreshSubject$ = new BehaviorSubject<void>(null as any);

        this.unreadGuestsCount$ = this.refreshSubject$.pipe(
            debounceTime(750),
            switchMap(() => this.getUser()),
            switchMap((user) => this.unreadGuestsCount(user)),
            shareReplay({bufferSize: 1, refCount: true}),
        );
    }

    public refresh(): void {
        this.refreshSubject$.next();
    }

    private getUser(): Observable<User> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map((authState) => authState.user),
            map((user) => {
                if (!!user) return user;
                throw new Error('User is not authenticated');
            }),
        );
    }

    private unreadGuestsCount(user: User): Observable<number> {
        if (user.isWorker) {
            return this.catalogClient.workerOwnGuests_UnreadGuestsCount(user.Id);
        } else if (user.isEmployer) {
            return this.catalogClient.employerOwnGuests_UnreadGuestsCount(user.Id);
        }
        return throwError(new Error('Unknown user role type.'));
    }
}
